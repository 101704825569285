<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Name input -->
      <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama..."
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- Description input -->
      <b-form-group
        id="input-group-notes"
        
      >
        <label for="input-notes">Deskripsi: <em class="text-muted">Opsional</em></label>
        <b-form-textarea
          id="input-notes"
          v-model="form.description"
          placeholder="Deskripsi..."
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <!-- Is_tax input -->
          <b-form-group
          id="input-group-tax"
          label="Jenis Remunerasi :"
          label-for="input-tax"
        >
          <b-form-select
            id="input-tax"
            v-model="form.is_tax"
            :options="options"
            required
            aria-placeholder="Pilih Tipe"
          ></b-form-select>
            <small class="text-danger">{{ error.is_tax }}</small>
        </b-form-group>

      <!-- Period input -->
          <b-form-group
          id="input-group-period"
          label="Periode :"
          label-for="input-period"
        >
          <b-form-select
            id="input-period"
            v-model="form.period"
            :options="formats"
            @change="handleMonth"
            required
            aria-placeholder="Pilih Tipe"
          ></b-form-select>
            <small class="text-danger">{{ error.period }}</small>
        </b-form-group>
<!-- 
       Paid date input
      <b-form-group
        id="input-group-paid"
        label="Tanggal Dibayar(1) :"
        label-for="paid-date"
         v-if="dateStatus == 'first'||dateStatus == 'threeMonth' || dateStatus == 'sixMonth'"
      >
          <b-form-datepicker 
          id="paid-date" 
          v-model="date.date1" 
          class="mb-2"
          locale="id"
          placeholder="Pilih Tanggal..."
          ></b-form-datepicker>
      </b-form-group>
       
       Paid date input
      <b-form-group
        id="input-group-paid"
        label="Tanggal Dibayar(2) :"
        label-for="paid-date"
        v-if="dateStatus == 'threeMonth' || dateStatus == 'sixMonth'"
      >
          <b-form-datepicker 
          id="paid-date" 
          v-model="date.date2" 
          class="mb-2"
          locale="id"
          placeholder="Pilih Tanggal..."
          ></b-form-datepicker>
      </b-form-group>
       
       Paid date input
      <b-form-group
        id="input-group-paid"
        label="Tanggal Dibayar(3) :"
        label-for="paid-date"
        v-if="dateStatus == 'threeMonth'"
      >
          <b-form-datepicker 
          id="paid-date" 
          v-model="date.date3" 
          class="mb-2"
          locale="id"
          placeholder="Pilih Tanggal..."
          ></b-form-datepicker>
      </b-form-group>
       
       Paid date input
      <b-form-group
        id="input-group-paid"
        label="Tanggal Dibayar(4) :"
        label-for="paid-date"
         v-if="dateStatus == 'threeMonth'"
      >
          <b-form-datepicker 
          id="paid-date" 
          v-model="date.date4" 
          class="mb-2"
          locale="id"
          placeholder="Pilih Tanggal..."
          ></b-form-datepicker>
      </b-form-group> -->


      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/remuneration-types')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import moment from 'moment';

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // data 
      // date:{
      //   date1:"",
      //   date2:"",
      //   date3:"",
      //   date4:"",
      // },
      dataArray:[],
      dateStatus:"first",
      // option
      formats: [
        { text: 'Bulanan', value: 1 },
        { text: '3 Bulanan', value: 2 },
        { text: '6 Bulanan', value: 3 },
        { text: 'Tahunan', value: 4 }  
      ],
      options: [
        { text: 'Pembayaran', value: 0 },
        { text: 'Potongan', value: 1 },
        { text: 'Pajak', value: 2}
      ],
      landscape :true,
      // Error
      error: {
        name: "",
        description: "",
      },
    };
  },

  methods: {
    handleMonth(){
      if(this.form.period == 1 || this.form.period == 4 ){
        this.dateStatus = "first"
      }else if(this.form.period == 2 ){
        this.dateStatus = "threeMonth"
      }else if(this.form.period == 3){
        this.dateStatus = "sixMonth"
      }else if(this.form.period == "Bulanan"){
        this.handleGetMonth("Bulanan")
      }else if(this.form.period == "3 Bulanan"){
        this.handleGetMonth("3 Bulanan")
      }else if(this.form.period == "6 Bulanan"){
        this.handleGetMonth("6 Bulanan")
      }else if( this.form.period == "Tahunan"){
        this.handleGetMonth("Tahunan")
      }else{
        this.dateStatus = "first"
      }
    },

    // handleGetMonth(data){
    //   if(data == "Bulanan" ){
    //    this.form.period = 1
    //    this.dateStatus = "first"
    //    this.date.date1 = this.form.paid_date[0]
    //    console.log(this.form.paid_date[0]);
    //   }else if(data ==  "3 Bulanan"){
    //     this.form.period = 2
    //     this.dateStatus = "threeMonth"
    //     this.date.date1 = this.form.paid_date[0]
    //     this.date.date2 = this.form.paid_date[1]
    //     this.date.date3 = this.form.paid_date[2]
    //     this.date.date4 = this.form.paid_date[3]
    //     console.log(this.form.paid_date[0]);
    //   }else if(data == "6 Bulanan"){
    //     this.form.period = 3
    //     this.dateStatus = "sixMonth"
    //     this.date.date1 = this.form.paid_date[0]
    //     this.date.date2 = this.form.paid_date[1]
    //     console.log(this.form.paid_date[0]);
    //   }else if( data == "Tahunan"){
    //     this.form.period = 4
    //     this.dateStatus = "first"
    //     this.date.date1 = this.form.paid_date[0]
    //     console.log(this.form.paid_date[0]);
    //   }
    // },
    
    async formOnsubmit() {
      // if(this.dateStatus == "first"){
      //   this.dataArray.push(this.date.date1)
      // }else if (this.dateStatus == "threeMonth"){
      //   this.dataArray.push(this.date.date1)
      //   this.dataArray.push(this.date.date2)
      //   this.dataArray.push(this.date.date3)
      //   this.dataArray.push(this.date.date4)
      // }else if(this.dateStatus == "sixMonth"){
      //   this.dataArray.push(this.date.date1)
      //   this.dataArray.push(this.date.date2)
      // }
      
      // this.form.paid_date = JSON.stringify(this.dataArray)
      this.form.paid_date = null

      // this.form.period = moment(this.form.period).format('M')
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/masters/remuneration-types");
      }
    },
  },
  mounted(){
    this.handleMonth()
  }
};
</script>

<style>
</style>